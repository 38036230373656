import './styles.scss';

import { PlusOutlined } from '@ant-design/icons';
import { IContact } from '@interfaces/contact';
import { useModal } from '@refinedev/antd';
import { useApiUrl, useCustom, usePermissions } from '@refinedev/core';
import { convertPrice, formatPrice, isHavingPermission } from '@utils/resource';
import { Button, Empty, Modal } from 'antd';
import { DataProviderNameEnum } from 'dataProvider';
import { useTranslation } from 'react-i18next';

import { PERMISSIONS } from '../../../../constants/permission';
import { IPartner } from '../../../../interfaces/partner';
import { PartnerCrmNoteItemForm } from '../partner-note/note-form';
import { PartnerCrmNoteList } from '../partner-note/note-list';

export const PartnerCrmInsights: React.FC<{
    partnerId: string;
}> = ({ partnerId }) => {
    const { t } = useTranslation(['quote', 'common', 'contact', 'partner']);

    const apiUrl = useApiUrl(DataProviderNameEnum.INTERNAL_CRM);

    const dataProviderName = DataProviderNameEnum.INTERNAL_CRM;
    const { data: permissions = [] } = usePermissions<string[]>();

    const { data: insightsData } = useCustom<IPartner.InvoicesOfResellerDto>({
        dataProviderName,
        url: `${apiUrl}/v1/invoices/invoices-reseller/${partnerId}`,
        method: 'get',
    });
    const { modalProps: modalNoteProps, show: showModalNote, close: closeModalNote } = useModal();
    const handleAddNewNote = () => {
        showModalNote();
    };

    return (
        <>
            <div className="flex flex-col gap-4 ">
                <div className="mb-4">
                    <div className="font-bold title h-11 flex items-center mb-3 mt-1">
                        <span>{t('contact.insight', { ns: 'common' })}</span>
                    </div>
                    <div className="contact-insight contact-total-insight">
                        <h4>
                            {t('partners.fields.commissionRate.total_commission', {
                                ns: 'partner',
                            })}
                        </h4>
                        <h3 className="mb-2">{convertPrice(insightsData?.data.sumCommission)}</h3>
                        <span className="text-gray-400">
                            {(insightsData?.data.totalTransaction ?? 0) >= 1
                                ? insightsData?.data.totalTransaction
                                : 0}{' '}
                            {insightsData?.data.totalTransaction &&
                            insightsData.data.totalTransaction > 1
                                ? t('contact.transactions', { ns: 'common' })
                                : t('contact.transaction', { ns: 'common' })}
                        </span>
                    </div>
                </div>
                <div className="subscription !m-0 ">
                    <div className="flex flex-row justify-between">
                        <div className="font-bold title flex items-center h-11 mb-3">
                            <span>{t('contacts.note', { ns: 'contact' })}</span>
                        </div>
                        {isHavingPermission(permissions, PERMISSIONS.WL_PARTNER_EDIT) && (
                            <Button
                                onClick={() => handleAddNewNote()}
                                className="contact-button"
                                type="text"
                                icon={<PlusOutlined />}
                            >
                                {t('add_new', { ns: 'common' })}
                            </Button>
                        )}
                    </div>
                    <PartnerCrmNoteList userId={partnerId} />
                </div>

                <Modal
                    {...modalNoteProps}
                    title={t('contacts.note_content.add_new', { ns: 'contact' })}
                    maskClosable={false}
                    footer={null}
                    width={650}
                    wrapClassName="payment-modal"
                >
                    <div className="mt-2">
                        <PartnerCrmNoteItemForm
                            userId={partnerId}
                            onCancelEdit={() => {
                                closeModalNote();
                            }}
                            onUpdate={() => {
                                closeModalNote();
                            }}
                        />
                    </div>
                </Modal>
            </div>
        </>
    );
};
