import './styles.scss';

import { NPDFMakeSetting } from '@interfaces/pdf-make.setting';
import { useApiUrl, useCustom, useGetIdentity } from '@refinedev/core';
import { Button, Dropdown, MenuProps, notification } from 'antd';
import * as pdfMake from 'pdfmake/build/pdfmake';
import { Content, Node, TDocumentDefinitions } from 'pdfmake/interfaces';
import { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { DataProviderNameEnum } from '../../../../dataProvider';
import { ExportTypeEnum } from '../../../../enums/export-file.enum';
import { IUser } from '../../../../interfaces/user';
import { assignLayoutTable, defaultStyle, footer, header, styles } from '../../../../utils/pdf';
import { Subscribe } from '../../mqttt-subscribe/mqtt-subscribe';

type DownloadPrintProps = {
    title: string;
    resellerId?: string;
    resellerName?: string;
    dataToPrint?: NPDFMakeSetting.IReportBody;
    isExportExcel?: boolean;
    reportType?: ExportTypeEnum;
    filter?: string;
    timeZone?: string;
};

export const DownloadPrint: FC<DownloadPrintProps> = ({
    title,
    resellerId,
    resellerName,
    dataToPrint,
    reportType,
    filter,
    timeZone,
}) => {
    const [content, setContent] = useState<Content[]>([]);
    const [loading, setLoading] = useState(false);
    const { data: userIdentity } = useGetIdentity<IUser.IUserDto>();
    const currentUserId = userIdentity?.id;
    const [userId, setUserId] = useState<string>();
    const dataProviderName = DataProviderNameEnum.INTERNAL_CRM;
    const apiUrl = useApiUrl(DataProviderNameEnum.INTERNAL_CRM);
    const objDefinition: TDocumentDefinitions = {
        pageSize: 'A4',
        pageMargins: [40, 80, 40, 80],
        content: content,
        styles: styles(),
        header: header(title, ''),
        defaultStyle: defaultStyle(),
        footer: (currentPage: number) => footer(currentPage),
        pageBreakBefore: function (currentNode: Node, followingNodesOnPage: Array<Node>) {
            return (
                currentNode.headlineLevel === 1 &&
                !followingNodesOnPage.filter((node: Node) => node.headlineLevel === 3).length
            );
        },
    };
    const { t } = useTranslation('common');
    useEffect(() => {
        const newContent: Content[] = [];

        const columnData: any = [];
        if (dataToPrint?.summarySection.length) {
            dataToPrint.summarySection.forEach((item: any) => {
                columnData.push(item);
            });
        }
        newContent.push(columnData);
        if (dataToPrint?.tableBody.length) {
            dataToPrint?.tableBody.forEach((item: any) => {
                assignLayoutTable(item);
                newContent.push(item);
            });
        }

        if (dataToPrint?.tableBodyQty?.length) {
            dataToPrint?.tableBodyQty.forEach((item: any) => {
                assignLayoutTable(item);
                newContent.push(item);
            });
        }
        setContent(newContent);
    }, [resellerName, dataToPrint]);

    const handleDownload = () => {
        const pdfDocGenerator = pdfMake.createPdf(objDefinition);
        pdfDocGenerator.download(`${title}.pdf`);
    };

    const onMessage = useCallback((message: string) => {
        const newMessage = JSON.parse(message);
        if (newMessage.data.path !== '') {
            setUserId(undefined);
            window.location.replace(newMessage.data.path);
            setLoading(false);
        }
    }, []);

    const { data: exportPath, refetch: getExportReport } = useCustom<any>({
        dataProviderName,
        url: `${apiUrl}/v1/export`,
        method: 'get',
        queryOptions: { enabled: false },
        config: {
            filters: [
                {
                    field: 'type',
                    operator: 'eq',
                    value: reportType,
                },
                {
                    field: 'filter',
                    operator: 'eq',
                    value: filter,
                },
                {
                    field: 'resellerId',
                    operator: 'eq',
                    value: resellerId,
                },
                {
                    field: 'timeZone',
                    operator: 'eq',
                    value: timeZone,
                },
            ],
        },
    });
    useEffect(() => {
        if (exportPath?.data.data.status == 'completed') {
            setUserId(undefined);
            window.location.replace(`${exportPath?.data.data.path}`);
        }
    }, [exportPath]);

    const exportToExcel = () => {
        setUserId(currentUserId);
        getExportReport();
        notification.success({
            className: 'success-notification',
            message: '',
            description: t('export_processing', { ns: 'common' }),
        });
        setLoading(true);
    };

    const items: MenuProps['items'] = [
        {
            key: '1',
            label: (
                <Button type="link" className="button-download" onClick={handleDownload}>
                    PDF
                </Button>
            ),
        },
        {
            key: '2',
            label: (
                <Button
                    disabled={loading}
                    type="link"
                    className="button-download"
                    onClick={exportToExcel}
                >
                    EXCEL
                </Button>
            ),
        },
    ];
    return (
        <div className={`flex justify-between items-center export-report`}>
            <Dropdown
                menu={{ items }}
                placement="bottomRight"
                arrow={{ pointAtCenter: true }}
                className="dropdown-download"
            >
                <Button
                    type="link"
                    icon={<img src="/images/icons/download.svg" />}
                    className="flex justify-center"
                />
            </Dropdown>
            {userId ? <Subscribe onMessage={onMessage} currentUser={userId} /> : <></>}
        </div>
    );
};
